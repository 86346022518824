//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  useContext,
  useStatic,
  computed,
} from '@nuxtjs/composition-api'
import { useCurrentTime } from '~/helpers/current-time'
export default defineComponent({
  setup() {
    const { $content } = useContext()

    const pages_loading = useStatic(async () => {
      const todayStr = new Date().toISOString().substring(0, 10);

      const upcomingEvents = await $content('veranstaltung')
        .only(['slug', 'title', 'begin', 'ende', 'featuredImage', 'tags'])
        .where({ 'ende': { $gte: new Date() } })
        .sortBy('begin')
        .limit(3)
        .fetch()

      const recentPosts = await $content('blog')
        .only([
          'title',
          'tags',
          'description',
          'featuredImage',
          'slug',
          'published',
        ])
        .sortBy('published', 'desc')
        .limit(3)
        .fetch()

        const countdown = {
          date: '2024-12-01T14:00:00Z',
          show: true
        }

        return { upcomingEvents, recentPosts, countdown }
    }, undefined, 'homeData')

    const pages = computed(() =>
      pages_loading.value
        ? pages_loading.value
        : {
            upcomingEvents: [],
            recentPosts: [],
            countdown: { date: undefined, show: false },
          }
    )

    const { currentTime } = useCurrentTime()

    const isCountdownFuture = computed(() => {
      const { countdown } = pages.value

      return new Date(countdown.date) > currentTime.value || false
    })

    return {
      pages,
      mail: (m) => (location.href = `mailto:${m}`),
      isCountdownFuture,
    }
  },
  head: {
    title: 'Startseite',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content:
          'Die EC-Arbeit in Deutschland hat den Auftrag, junge Menschen zu Jüngern zu machen und sie zu prägenden Persönlichkeiten heranzubilden, durch die wiederum Menschen ihrer Generation zu Jüngern werden. Der EC-Nordbund ist einer der 18 Landesverbänden des Deutschen EC-Verbandes. Im EC-Nordbund sind alle EC-Kinder- und Jugendarbeiten aus Schleswig-Holstein und Hamburg vereint. EC bedeutet: „Entschieden für Christus“ und markiert die Aurichtung auf Jesus in allen unseren Aktivitäten.',
      },
      // Open Graph
      { hid: 'og:title', property: 'og:title', content: 'EC-Nordbund' },
      {
        hid: 'og:description',
        property: 'og:description',
        content:
          'Die EC-Arbeit in Deutschland hat den Auftrag, junge Menschen zu Jüngern zu machen und sie zu prägenden Persönlichkeiten heranzubilden, durch die wiederum Menschen ihrer Generation zu Jüngern werden. Der EC-Nordbund ist einer der 18 Landesverbänden des Deutschen EC-Verbandes. Im EC-Nordbund sind alle EC-Kinder- und Jugendarbeiten aus Schleswig-Holstein und Hamburg vereint. EC bedeutet: „Entschieden für Christus“ und markiert die Aurichtung auf Jesus in allen unseren Aktivitäten.',
      },
      // Twitter Card
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: 'EC-Nordbund!',
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content:
          'Die EC-Arbeit in Deutschland hat den Auftrag, junge Menschen zu Jüngern zu machen und sie zu prägenden Persönlichkeiten heranzubilden, durch die wiederum Menschen ihrer Generation zu Jüngern werden. Der EC-Nordbund ist einer der 18 Landesverbänden des Deutschen EC-Verbandes. Im EC-Nordbund sind alle EC-Kinder- und Jugendarbeiten aus Schleswig-Holstein und Hamburg vereint. EC bedeutet: „Entschieden für Christus“ und markiert die Aurichtung auf Jesus in allen unseren Aktivitäten.',
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: 'https://www.ec-nordbund.de',
        hid: 'canonical',
      }
    ],
  },
})
