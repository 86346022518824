import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7a3206ee&scoped=true&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=7a3206ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a3206ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EcCountdown: require('/home/runner/work/EC-Website/EC-Website/components/ecCountdown.vue').default,VCard: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VCard/VCard.js').default,VCol: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VCol.js').default,VRow: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VRow.js').default,VContainer: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VGrid/VContainer.js').default,VImg: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VImg/VImg.js').default,VAlert: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VAlert/VAlert.js').default,VIcon: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VIcon/VIcon.js').default,VBtn: require('/home/runner/work/EC-Website/EC-Website/node_modules/vuetify/lib/components/VBtn/VBtn.js').default,EcImageItem: require('/home/runner/work/EC-Website/EC-Website/components/ecImageItem.vue').default})
